import { SocialChannelItem, SocialChannelItemV1 } from '../types/template-management.type';

const convertSocialV2ToSocialV1 = (item: SocialChannelItem): SocialChannelItemV1 => {
    const { _id, title, data } = item;
    const { channel, adType, category, image, icon, description, interfaceSetup, settings } = data;
    return {
        ...data,
        channel: channel || '',
        adType: adType || '',
        category: category || '',
        image: image || '',
        icon: icon || '',
        description: description || '',
        type: 'socialChannelItem',
        interfaceSetup: interfaceSetup || {},
        settings: settings || {},
        title,
        id: _id
    };
};
export { convertSocialV2ToSocialV1 };

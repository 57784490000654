import { AssetManagementConfig } from 'components/asset-management/types/asset-management-config.type';
import { TEMPLATE_MANAGEMENT_DEFAULT_SETUP } from './default-setup';
import { STATIC_ASSETS, STATIC_ASSET_TYPES } from './static-assets';
import { SOCIAL_CHANNEL_ITEMS } from './social';
import {
    CREATIVE_TEMPLATE_TYPES,
    CUSTOM_TEMPLATE_TYPES,
    TD_TEMPLATE_TYPES,
    CUSTOM_CREATIVE_BUILDER_TEMPLATES_TYPES,
    EMAIL_TEMPLATE_TYPES,
    CREATIVE_BUILDER_ITEM_TYPES,
    TEMPLATE_MANAGEMENT_TEMPLATE_TYPES
} from './template-types';

const TEMPLATE_MANAGEMENT_CONFIG: AssetManagementConfig = {
    storeName: 'TemplateManagement',
    type: 'template',
    gridType: 'grid',
    gridColumnTargetSize: 270,
    dropZone: false,
    versioning: true,
    fullDataInList: false,
    setupResource: 'setup_templateManagement',
    assetsPlaceholder: 'template',
    assetsListViewHeaderKeys: ['empty', 'title', 'type', 'content', 'lastModified', 'empty'],
    subtypeIcons: {
        displayAdDesigned: 'web',
        dynamicVideoDesigned: 'movie',
        dynamicImageDesigned: 'image',
        dynamicPDFDesigned: 'picture_as_pdf',
        dynamicAfterEffects: 'movie',
        dynamicInDesign: 'description_outlined',
        displayAd: 'web',
        dynamicImage: 'image',
        dynamicVideo: 'movie',
        dynamicPDF: 'picture_as_pdf',
        emailBase: 'mail',
        emailBlock: 'mail',
        landingpageBlock: 'web',
        socialChannelItem: 'groups',
        staticAsset: 'design_services'
    }
};

export {
    TEMPLATE_MANAGEMENT_CONFIG,
    TEMPLATE_MANAGEMENT_DEFAULT_SETUP,
    CREATIVE_TEMPLATE_TYPES,
    CUSTOM_TEMPLATE_TYPES,
    TD_TEMPLATE_TYPES,
    CUSTOM_CREATIVE_BUILDER_TEMPLATES_TYPES,
    STATIC_ASSETS,
    STATIC_ASSET_TYPES,
    SOCIAL_CHANNEL_ITEMS,
    EMAIL_TEMPLATE_TYPES,
    CREATIVE_BUILDER_ITEM_TYPES,
    TEMPLATE_MANAGEMENT_TEMPLATE_TYPES
};

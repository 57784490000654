import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import TruncatedTextWithTooltip from 'components/ui-components-cape/TurncatedTextWithTooltip';
import Typography from 'components/ui-components-v2/Typography';
import Avatar from 'components/ui-components-v2/Avatar';

import '../styles/info.scss';

interface Props {
    title: string;
    subtitle: string;
    small?: boolean;
    icon?: string;
    iconUrl?: string;
}

const TemplateManagementAssetTileInfo = ({ title, subtitle, icon = 'design_services', iconUrl, small }: Props) => {
    return (
        <div className="template-management-asset-tile-info">
            {iconUrl ? <Avatar src={iconUrl} className="template-management-asset-tile-info__icon-image" /> : <Icon color="action">{icon}</Icon>}
            <div className="template-management-asset-tile-info__text">
                <TruncatedTextWithTooltip variant={small ? 'h5' : 'h4'} data-cy="templateManagement-assetTitle-div">
                    {title}
                </TruncatedTextWithTooltip>
                <Typography
                    variant="body2"
                    color="textSecondary"
                    noWrap
                    className="template-management-asset-tile-info__subtitle"
                    data-cy="templateManagement-assetSubTitle-div">
                    {subtitle}
                </Typography>
            </div>
        </div>
    );
};

export default TemplateManagementAssetTileInfo;

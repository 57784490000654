import React, { useMemo, useState } from 'react';
import { Filters } from 'types/filter.type';
import GridWrapper from 'components/ui-components/GridWrapper';
import { SOCIAL_CHANNEL_ITEMS } from 'components/template-management/constants';
import { convertSocialV1ToSocialV2 } from 'components/template-management/utilities';
import { SelectorSocialChannelItem } from 'components/asset-management/components/selector/types/selector-asset.type';
import { AssetManagementSelectorTabs } from 'components/asset-management/components/selector';
import Translation from 'components/data/Translation';
import GenericFilter from 'components/ui-base/GenericFilter';
import { TemplateManagementSelectorTile } from '../';

import '../styles/social.scss';

interface Props {
    groupKey?: string;
    itemIncludes?: string[];
    onSelectItem: (item: any) => void;
}

const TemplateManagementSelectorSocial = ({ groupKey, itemIncludes, onSelectItem }: Props) => {
    const [filters, setFilters] = useState<Filters>({ query: '', categories: [] });

    const displayItems = useMemo(() => {
        return SOCIAL_CHANNEL_ITEMS.filter((item) => {
            if (itemIncludes && itemIncludes.length > 0) {
                const itemIncludesLowerCase = itemIncludes.map((i) => i.toLowerCase());
                return itemIncludesLowerCase.includes(item.identifier.toLowerCase());
            }
            if (groupKey && groupKey.length > 0) {
                return item.groupKey && item.groupKey === groupKey;
            }
            return true;
        });
    }, [itemIncludes, groupKey]);

    const categories: string[] = useMemo(() => {
        return Array.from(
            new Set(displayItems.map((item) => item.category?.toLowerCase()).filter((category) => category !== null && category !== undefined))
        ) as string[];
    }, [displayItems]);

    const selectorItems = useMemo(() => {
        return displayItems.map((item) => ({
            id: item.id,
            title: item.title,
            subtitle: item.description || undefined,
            originalRecord: convertSocialV1ToSocialV2(item)
        }));
    }, [displayItems]);

    const filteredItems: SelectorSocialChannelItem[] = useMemo(() => {
        return selectorItems.filter((item) => {
            const matchesQuery = filters.query && filters.query.length > 0 ? item.title.toLowerCase().includes((filters.query as string).toLowerCase()) : true;

            const matchesCategory =
                filters.categories && filters.categories.length > 0
                    ? filters.categories.includes(item.originalRecord.data.category?.toLowerCase() ?? '')
                    : true;

            return matchesQuery && matchesCategory;
        });
    }, [selectorItems, filters]);

    const onChangeFilter = (filter: string, value: string | string[]) => {
        setFilters((prev) => ({ ...prev, [filter]: value }));
    };

    const changeQuery = (value: string) => {
        onChangeFilter('query', value);
    };

    return (
        <div className="template-management-selector-social">
            <div className="template-management-selector-social__toobar">
                <GenericFilter
                    inDialog
                    searchField
                    searchPlaceholder={Translation.get('labels.search', 'template-management')}
                    filterSetup={[]}
                    filters={filters}
                    hideChips
                    onSearch={changeQuery}
                    onChangeFilter={onChangeFilter}
                    onClearAll={() => changeQuery('')}
                    className="template-management-selector-social__filter-bar"
                />
                {categories.length > 1 && (
                    <AssetManagementSelectorTabs
                        tabs={[
                            { label: Translation.get('labels.all', 'common'), value: '_all' },
                            ...categories.map((category) => ({ label: category, value: category }))
                        ]}
                        activeTab={filters.categories?.[0] || '_all'}
                        className="asset-management-selector-assets__categories"
                        onChange={(value) => onChangeFilter('categories', value === '_all' ? [] : [value])}
                    />
                )}
            </div>
            <div className="template-management-selector-social__assets">
                <GridWrapper targetSize={220}>
                    {filteredItems.map((item) => (
                        <TemplateManagementSelectorTile key={item.title} item={item} onSelect={() => onSelectItem(item.originalRecord)} />
                    ))}
                </GridWrapper>
            </div>
        </div>
    );
};

export default TemplateManagementSelectorSocial;

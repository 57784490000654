import AssetManagementService from 'components/asset-management/services/asset-management.service';
import { convertAssetV2toTemplate } from 'components/data/Templates/helpers/template-converter';
import Templates from 'components/data/Templates';
import Translation from 'components/data/Translation';
import { TemplateAsset } from '../types/template-management.type';

// Fetches templates based on the template identifiers.
// If no templates arefound, it returns null.
// If released is true, the templates iare converted to the old format that CreativeEditor V1 understands and stored in the template class.
// The AMV2 Templates are returned
const fetchTemplates = async (templateIdentifiers: string[], released?: boolean): Promise<TemplateAsset[] | null> => {
    const response = await AssetManagementService.getAssetBatch(
        {
            type: 'template',
            filters: { status: ['available'] },
            dataFilters: { identifier: templateIdentifiers },
            filterReleased: released
        },
        { isVerbose: true }
    );
    if (response.assets.length === 0) return null;
    const templates = response.assets as TemplateAsset[];
    if (released) {
        templates.forEach((template) => {
            const convertedTemplate = convertAssetV2toTemplate(template);
            Templates.set(template.subType, template.data.identifier, convertedTemplate);
        });
    }
    if (response.assets.length > 1) {
        // eslint-disable-next-line no-console
        console.warn(Translation.get('snackbar.moreThenOneTemplate', 'creative-management-v2'));
    }
    return templates;
};

export { fetchTemplates };

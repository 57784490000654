import { SocialChannelItemV1 } from '../types/template-management.type';

const SOCIAL_CHANNEL_ITEMS: SocialChannelItemV1[] = [
    {
        type: 'socialChannelItem',
        channel: 'facebook',
        groupKey: '',
        adType: 'linkAd',
        identifier: 'facebook_linkad',
        category: 'facebook',
        image: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/7b492a80-6e90-11ea-838a-c9db71e62cfb_facebooklinkad1x.jpg',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/73edddd0-1d96-11ea-a088-dd3615efadcb_Facebook.png',
        title: 'Facebook Link Ad',
        description: 'A single Facebook link ad',
        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'message',
                                label: 'Text',
                                multiline: true,
                                placeholder: 'Your message at the top',
                                maxLength: 2200,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'divider'
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'name',
                                label: 'Headline',
                                validators: [
                                    {
                                        type: 'length',
                                        max: 40,
                                        severity: 'warning',
                                        message: 'Text over 40 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'description',
                                label: 'Description',
                                validators: [
                                    {
                                        type: 'length',
                                        max: 20,
                                        severity: 'warning',
                                        message: 'Text over 20 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'divider'
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'link',
                                label: 'URL',
                                validators: [
                                    {
                                        type: 'url',
                                        severity: 'error',
                                        message: 'This should be a valid url, including https://'
                                    }
                                ],
                                helperText: 'Enter the URL for the web page that you want people to visit',
                                tooltip: 'Please make sure to write the full URL, including https://',
                                placeholder: "e.g. 'https://domain.com'"
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'caption',
                                placeholder: 'Show a shortened link',
                                label: 'Display URL (optional)'
                            },
                            {
                                type: 'divider'
                            },
                            {
                                type: 'select',
                                model: 'cta',
                                label: 'Call to action',
                                defaultValue: 'OPEN_LINK',
                                resources: ['facebook_cta']
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1200,
                    height: 630
                },
                display: {
                    width: 320
                }
            },
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicImageDesigned', 'dynamicVideoDesigned', 'dynamicVideo', 'dynamicAfterEffects'],
            multiFrame: false
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'facebook',
        adType: 'post',
        identifier: 'facebook_post',
        category: 'facebook',
        image: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/24675fa0-6e8d-11ea-b497-9d3184110a39_facebookpost1x.jpg',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/928f1280-6761-11ea-a084-5b851f9a3c47_73edddd01d9611eaa088dd3615efadcbFacebook.png',
        title: 'Facebook Post',
        description: 'A single Facebook post',
        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'message',
                                label: 'Text',
                                multiline: true,
                                placeholder: 'Your message at the top',
                                maxLength: 2200,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1200,
                    height: 630
                },
                display: {
                    width: 320
                }
            },
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicVideo', 'dynamicVideoDesigned', 'dynamicImageDesigned', 'dynamicAfterEffects'],
            multiFrame: false
        }
    },
    {
        id: 477,
        global: 1,
        type: 'socialChannelItem',
        channel: 'facebook',
        adType: 'carouselAd',
        identifier: 'facebook_carouselAd',
        category: 'facebook',
        image: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/6509d2b0-6e90-11ea-838a-c9db71e62cfb_facebookcarouselad1x.jpg',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/554f8740-6763-11ea-a084-5b851f9a3c47_73edddd01d9611eaa088dd3615efadcbFacebook.png',
        title: 'Facebook Carousel Ad',
        description: 'A Facebook Carousel Ad',
        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'message',
                                label: 'Primary text',
                                placeholder: 'Your message at the top',
                                multiline: true,
                                maxLength: 2200,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'select',
                                model: 'cta',
                                label: 'Call to action',
                                resources: ['facebook_cta']
                            }
                        ]
                    }
                ]
            },
            {
                path: 'dynamicFrame',
                title: 'Copy',
                items: [
                    {
                        type: 'textMultiLanguage',
                        model: 'name',
                        label: 'Headline',
                        placeholder: 'Headline of the frame',
                        validators: [
                            {
                                type: 'length',
                                max: 40,
                                severity: 'warning',
                                message: 'Text over 40 characters is allowed, but may be truncated'
                            }
                        ]
                    },
                    {
                        type: 'textMultiLanguage',
                        model: 'description',
                        label: 'Description',
                        placeholder: 'Description of the frame',
                        validators: [
                            {
                                type: 'length',
                                max: 20,
                                severity: 'warning',
                                message: 'Text over 20 characters is allowed, but may be truncated'
                            }
                        ]
                    },
                    {
                        type: 'textMultiLanguage',
                        model: 'link',
                        label: 'Link',
                        helperText: 'Enter the URL for the web page that you want people to visit',
                        tooltip: 'Please make sure to write the full URL, including https://',
                        placeholder: "e.g. 'https://domain.com'",
                        validators: [
                            {
                                type: 'url',
                                severity: 'error',
                                message: 'This should be a valid url'
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1200,
                    height: 1200
                },
                display: {
                    width: 230
                }
            },
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicVideo', 'dynamicImageDesigned', 'dynamicVideoDesigned'],
            multiFrame: true,
            dynamicFrames: true
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'facebook',
        adType: 'storiesAd',
        identifier: 'facebook_storiesAd',
        category: 'facebook',
        image: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/2d9ab860-6e8d-11ea-b497-9d3184110a39_facebookstoriesad1x.jpg',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/17702270-6779-11ea-9af4-19bcfc61486c_73edddd01d9611eaa088dd3615efadcbFacebook.png',
        title: 'Facebook Stories Ad',
        description: 'A Facebook Stories Ad',
        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Copy',
                        items: [
                            {
                                type: 'select',
                                model: 'cta',
                                label: 'Call to action',
                                resources: ['facebook_cta']
                            }
                        ]
                    }
                ]
            },
            {
                path: 'dynamicFrame',
                title: 'Copy',
                items: [
                    {
                        type: 'textMultiLanguage',
                        model: 'link',
                        label: 'Link',
                        helperText: 'Enter the URL for the webpage that you want people to visit',
                        placeholder: "e.g. 'https://domain.com'",
                        validators: [
                            {
                                type: 'url',
                                severity: 'error',
                                message: 'This should be a valid url, including https://'
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1080,
                    height: 1920
                },
                display: {
                    width: 320
                }
            },
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicVideo', 'dynamicImageDesigned', 'dynamicVideoDesigned', 'dynamicAfterEffects'],
            multiFrame: true,
            dynamicFrames: true,
            useCropper: true
        }
    },
    {
        id: 479,
        global: 1,
        type: 'socialChannelItem',
        channel: 'instagram',
        adType: 'linkAd',
        identifier: 'instagram_linkad',
        category: 'instagram',
        image: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/9a16c170-6e90-11ea-838a-c9db71e62cfb_instagramlinkad1x.jpg',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/4e41b9b0-677b-11ea-803d-1dcb09e0f0cf_5c7d28901daf11eaa61faf6badbd46f5Instagram.png',
        title: 'Instagram Link Ad',
        description: 'A single Instagram Link Ad',
        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        path: 'copy',
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'message',
                                label: 'Text',
                                multiline: true,
                                placeholder: 'Your message at the bottom',
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ],
                                maxLength: 2200
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'link',
                                label: 'Link',
                                helperText: 'Enter the URL for the web page that you want people to visit',
                                tooltip: 'Please make sure to write the full URL, including https://',
                                placeholder: "e.g. 'https://domain.com",
                                validators: [
                                    {
                                        type: 'url',
                                        severity: 'error',
                                        message: 'This should be a valid url, including https://'
                                    }
                                ]
                            },
                            {
                                type: 'divider'
                            },
                            {
                                type: 'select',
                                model: 'cta',
                                label: 'Call to action',
                                defaultValue: 'OPEN_LINK',
                                resources: ['instagram_cta']
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1200,
                    height: 1200
                },
                display: {
                    width: 320
                }
            },
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicVideo', 'dynamicImageDesigned', 'dynamicVideoDesigned', 'dynamicAfterEffects'],
            multiFrame: false
        }
    },
    {
        id: 480,
        global: 1,
        type: 'socialChannelItem',
        channel: 'instagram',
        adType: 'post',
        identifier: 'instagram_post',
        category: 'instagram',
        image: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/5bde2270-6e8d-11ea-b497-9d3184110a39_instagrampost1x.jpg',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/8c9f3b40-677d-11ea-803d-1dcb09e0f0cf_5c7d28901daf11eaa61faf6badbd46f5Instagram.png',
        title: 'Instagram Post',
        description: 'A single Instagram post',
        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        path: 'copy',
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'text',
                                label: 'Text',
                                multiline: true,
                                maxLength: 2200,
                                placeholder: 'Your message at the bottom',
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1200,
                    height: 1200
                },
                display: {
                    width: 320
                }
            },
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicVideo', 'dynamicImageDesigned', 'dynamicVideoDesigned', 'dynamicAfterEffects'],
            assetFilters: {
                dynamicImage: {
                    formats: ['square', 'newsfeed4x5']
                },
                dynamicVideo: {
                    formats: ['main1x1', 'main4x5', 'main5x4', 'main16x9', '1080x1080', '1350x1080', '1080x1350', '1920x1080']
                },
                dynamicImageDesigned: {
                    formats: ['square', 'newsfeed4x5', '1080x1080', '1350x1080', '1080x1350', '1920x1080']
                },
                dynamicVideoDesigned: {
                    formats: ['square', 'main1x1', 'main4x5', 'main5x4', 'main16x9', '1080x1080', '1350x1080', '1080x1350', '1920x1080']
                }
            },
            multiFrame: false
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'instagram',
        adType: 'carouselAd',
        identifier: 'instagram_carouselAd',
        category: 'instagram',
        image: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/3fce7300-6e8d-11ea-b497-9d3184110a39_instagramcarouselad1x.jpg',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/540b39e0-6783-11ea-b376-b3c3bf48da25_5c7d28901daf11eaa61faf6badbd46f5Instagram.png',
        title: 'Instagram Carousel Ad',
        description: 'An Instagram carousel ad',
        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'message',
                                label: 'Primary text',
                                multiline: true,
                                placeholder: 'Your message at the bottom',
                                maxLength: 2200,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'select',
                                model: 'cta',
                                label: 'Call to action',
                                resources: ['instagram_cta']
                            }
                        ]
                    }
                ]
            },
            {
                path: 'dynamicFrame',
                title: 'Copy',
                items: [
                    {
                        type: 'textMultiLanguage',
                        model: 'name',
                        label: 'Headline (optional)',
                        maxLength: 2200,
                        placeholder: 'Headline of the frame',
                        validators: [
                            {
                                type: 'length',
                                max: 40,
                                severity: 'warning',
                                message: 'Text over 40 characters is allowed, but may be truncated'
                            }
                        ]
                    },
                    {
                        type: 'textMultiLanguage',
                        model: 'link',
                        label: 'Link',
                        helperText: 'Enter the URL for the web page that you want people to visit',
                        tooltip: 'Please make sure to write the full URL, including https://',
                        placeholder: "e.g. 'https://domain.com",
                        validators: [
                            {
                                type: 'url',
                                severity: 'error',
                                message: 'This should be a valid url, including https://'
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1200,
                    height: 1200
                },
                display: {
                    width: 320
                }
            },
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicVideo', 'dynamicImageDesigned', 'dynamicVideoDesigned'],
            multiFrame: true,
            dynamicFrames: true
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'instagram',
        adType: 'storiesAd',
        identifier: 'instagram_storiesAd',
        category: 'instagram',
        image: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/64823380-6e8d-11ea-b497-9d3184110a39_instagramstoryad1x.jpg',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/97f289a0-6784-11ea-b376-b3c3bf48da25_5c7d28901daf11eaa61faf6badbd46f5Instagram.png',
        title: 'Instagram Stories Ad',
        description: 'An Instagram stories ad',

        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Copy',
                        items: [
                            {
                                type: 'select',
                                model: 'cta',
                                label: 'Call to action',
                                resources: ['instagram_cta']
                            }
                        ]
                    }
                ]
            },
            {
                path: 'dynamicFrame',
                title: 'Copy',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        path: 'copy',
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'link',
                                label: 'Link',
                                helperText: 'Enter the URL for the web page that you want people to visit',
                                placeholder: "e.g. 'https://domain.com'",
                                validators: [
                                    {
                                        type: 'url',
                                        severity: 'error',
                                        message: 'This should be a valid url, including https://'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1080,
                    height: 1920
                },
                display: {
                    width: 320
                }
            },
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicVideo', 'dynamicImageDesigned', 'dynamicVideoDesigned', 'dynamicAfterEffects'],
            multiFrame: true,
            dynamicFrames: true
        }
    },
    {
        id: 483,
        global: 1,
        type: 'socialChannelItem',
        channel: 'linkedin',

        adType: 'linkAd',
        identifier: 'linkedin_linkad',
        category: 'linkedin',
        image: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/80c8aa60-6e8d-11ea-b497-9d3184110a39_linkedinlinkad1x.jpg',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/e389ed80-6785-11ea-bdf8-bfc404589a1c_7cda15401db311eaa61faf6badbd46f5LinkedIn.png',
        title: 'LinkedIn Link Ad',
        description: 'A single LinkedIn link ad',

        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        path: 'copy',
                        title: 'Copy',
                        items: [
                            {
                                type: 'switch',
                                model: 'directsponsored',
                                label: 'Direct Sponsored',
                                tooltip:
                                    'Is sponsored content that does not appear on the LinkedIn Page or Showcase Page as an update before it’s served as an advertisement.'
                            },
                            {
                                type: 'divider'
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'text',
                                label: 'Text',
                                maxLength: 600,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 150,
                                        severity: 'warning',
                                        message: 'Text over 150 characters is allowed, but may be truncated'
                                    }
                                ],
                                placeholder: 'Your message at the top'
                            },
                            {
                                type: 'divider'
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'headline',
                                label: 'Headline',
                                placeholder: 'Your message at the bottom',
                                maxLength: 200,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 70,
                                        severity: 'warning',
                                        message: 'Text over 70 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'description',
                                label: 'Description',
                                maxLength: 300,
                                tooltip:
                                    'Your ad description gives people who see the ad more information. The ad description is not visible in most cases and only appears for a small part of the LinkedIn members, such as the people who view it on LinkedIn Audience Network (if enabled)',
                                helperText: 'Not visible in most cases'
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'displayUrl',
                                label: 'Destination URL',
                                maxLength: 150,
                                helperText: 'Enter the URL for the web page that you want people to visit',
                                placeholder: "e.g. 'https://domain.com'",
                                tooltip: "URLs must have the 'http://' 'or https://' prefix. URLs longer than 23 characters are automatically shortened",
                                validators: [
                                    {
                                        type: 'url',
                                        severity: 'error',
                                        message: 'This should be a valid url, including https://'
                                    }
                                ]
                            },
                            {
                                type: 'divider'
                            },
                            {
                                type: 'select',
                                model: 'cta',
                                label: 'Call to action (optional)',
                                resources: ['linkedin_cta']
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1200,
                    height: 630
                },
                display: {
                    width: 320
                }
            },
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicVideo', 'dynamicImageDesigned', 'dynamicVideoDesigned'],
            multiFrame: false
        }
    },
    /*
    {
        type: 'socialChannelItem',
        channel: 'linkedin',
        adType: 'textAd',
        identifier: 'linkedin_textad',
        category: 'linkedin',
        image: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/ab0cac40-6e8d-11ea-b497-9d3184110a39_linkedinpromoted1x.jpg',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/07284230-678d-11ea-bdf8-bfc404589a1c_7cda15401db311eaa61faf6badbd46f5LinkedIn.png',
        title: 'LinkedIn Text Ad',
        description: 'A single Linkedin text ad',
        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        path: 'copy',
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'headline',
                                label: 'Headline',
                                maxLength: 25
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'displayUrl',
                                label: 'Destination URL',
                                tooltip: "URLs must have the 'http://' or 'https://' prefix.",
                                maxLength: 500,
                                helperText: 'Enter the URL for the web page that you want people to visit',
                                validators: [
                                    {
                                        type: 'url',
                                        severity: 'error',
                                        message: 'This should be a valid url, including https://'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'description',
                                label: 'Description',
                                multiline: true,
                                maxLength: 75
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1200,
                    height: 1200
                },
                display: {
                    width: 56,
                    height: 56
                }
            },
            assetTypes: ['image'],
            multiFrame: false
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'linkedin',
        adType: 'carouselAd',
        identifier: 'linkedin_carouselAd',
        category: 'linkedin',
        image: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/76d9a2c0-6e8d-11ea-b497-9d3184110a39_linkedincarousel1x.jpg',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/8b21a150-6790-11ea-bdf8-bfc404589a1c_7cda15401db311eaa61faf6badbd46f5LinkedIn.png',
        title: 'LinkedIn Carousel Ad',
        description: 'A LinkedIn Carousel Ad',
        version: null,
        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Copy',
                        items: [
                            {
                                type: 'switch',
                                model: 'directsponsored',
                                label: 'Direct Sponsored',
                                tooltip:
                                    'Is sponsored content that does not appear on the LinkedIn Page or Showcase Page as an update before it’s served as an advertisement.'
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'text',
                                label: 'Text',
                                placeholder: 'Your message at the top',
                                maxLength: 255,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 150,
                                        severity: 'warning',
                                        message: 'Use up to 150 characters to avoid truncation (desktop max of 255 characters)'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                path: 'dynamicFrame',
                title: 'Copy',
                items: [
                    {
                        type: 'textMultiLanguage',
                        model: 'headline',
                        label: 'Headline',
                        placeholder: 'Your message in frame',
                        maxLength: 255,
                        validators: [
                            {
                                type: 'length',
                                max: 150,
                                severity: 'warning',
                                message: 'Use up to 150 characters to avoid truncation (desktop max of 255 characters)'
                            }
                        ]
                    },
                    {
                        type: 'textMultiLanguage',
                        model: 'displayUrl',
                        label: 'Destination URL',
                        placeholder: "e.g. 'https://domain.com'",
                        helperText: 'Enter the URL for the web page that you want people to visit',
                        tooltip: "URLs must have the 'http://' 'or https://' prefix. You may use up to 2,000 characters for the destination link.",
                        validators: [
                            {
                                type: 'url',
                                severity: 'error',
                                message: 'This should be a valid url, including https://'
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1200,
                    height: 1200
                },
                display: {
                    width: 260
                }
            },
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicVideo', 'dynamicImageDesigned', 'dynamicVideoDesigned'],
            assetFilters: {
                dynamicImage: {
                    formats: ['square', 'main1x1']
                },
                dynamicVideo: {
                    formats: ['square', 'main1x1']
                },
                dynamicImageDesigned: {
                    formats: ['square', 'main1x1']
                },
                dynamicVideoDesigned: {
                    formats: ['square', 'main1x1', 'horizontal']
                }
            },
            multiFrame: true,
            dynamicFrames: true
        }
    },*/
    {
        type: 'socialChannelItem',
        channel: 'linkedin',

        adType: 'post',
        identifier: 'linkedin_post',
        category: 'linkedin',
        image: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/a23a8650-6e8d-11ea-b497-9d3184110a39_linkedinpost1x.jpg',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/4b96ed90-6792-11ea-a962-8f0c6bcc4e06_7cda15401db311eaa61faf6badbd46f5LinkedIn.png',
        title: 'LinkedIn Post',
        description: 'A single LinkedIn post',
        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        path: 'copy',
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'text',
                                label: 'Text',
                                multiline: true,
                                maxLength: 3000,
                                placeholder: 'Your message at the top',
                                validators: [
                                    {
                                        type: 'length',
                                        max: 210,
                                        severity: 'warning',
                                        message: 'Use up to 210 characters to avoid truncation (desktop max of 3000 characters)'
                                    }
                                ]
                            },
                            {
                                type: 'autocomplete',
                                model: 'seniority',
                                label: 'Seniority targeting',
                                options: {
                                    'urn:li:seniority:1': 'Unpaid',
                                    'urn:li:seniority:2': 'Training',
                                    'urn:li:seniority:3': 'Entry',
                                    'urn:li:seniority:4': 'Senior',
                                    'urn:li:seniority:5': 'Manager',
                                    'urn:li:seniority:6': 'Director',
                                    'urn:li:seniority:7': 'VP',
                                    'urn:li:seniority:8': 'CXO',
                                    'urn:li:seniority:9': 'Partner',
                                    'urn:li:seniority:10': 'Owner'
                                }
                            },
                            {
                                type: 'autocomplete',
                                model: 'industry',
                                label: 'Industry targeting',
                                options: {
                                    'urn:li:industry:1': 'Defense & Space',
                                    'urn:li:industry:3': 'Computer Hardware',
                                    'urn:li:industry:4': 'Computer Software',
                                    'urn:li:industry:5': 'Computer Networking',
                                    'urn:li:industry:6': 'Internet',
                                    'urn:li:industry:7': 'Semiconductors',
                                    'urn:li:industry:8': 'Telecommunications',
                                    'urn:li:industry:9': 'Law Practice',
                                    'urn:li:industry:10': 'Legal Services',
                                    'urn:li:industry:11': 'Management Consulting',
                                    'urn:li:industry:12': 'Biotechnology',
                                    'urn:li:industry:13': 'Medical Practice',
                                    'urn:li:industry:14': 'Hospital & Health Care',
                                    'urn:li:industry:15': 'Pharmaceuticals',
                                    'urn:li:industry:16': 'Veterinary',
                                    'urn:li:industry:17': 'Medical Device',
                                    'urn:li:industry:18': 'Cosmetics',
                                    'urn:li:industry:19': 'Apparel & Fashion',
                                    'urn:li:industry:20': 'Sporting Goods',
                                    'urn:li:industry:21': 'Tobacco',
                                    'urn:li:industry:22': 'Supermarkets',
                                    'urn:li:industry:23': 'Food Production',
                                    'urn:li:industry:24': 'Consumer Electronics',
                                    'urn:li:industry:25': 'Consumer Goods',
                                    'urn:li:industry:26': 'Furniture',
                                    'urn:li:industry:27': 'Retail',
                                    'urn:li:industry:28': 'Entertainment',
                                    'urn:li:industry:29': 'Gambling & Casinos',
                                    'urn:li:industry:30': 'Leisure, Travel & Tourism',
                                    'urn:li:industry:31': 'Hospitality',
                                    'urn:li:industry:32': 'Restaurants',
                                    'urn:li:industry:33': 'Sports',
                                    'urn:li:industry:34': 'Food & Beverages',
                                    'urn:li:industry:35': 'Motion Pictures & Film',
                                    'urn:li:industry:36': 'Broadcast Media',
                                    'urn:li:industry:37': 'Museums & Institutions',
                                    'urn:li:industry:38': 'Fine Art',
                                    'urn:li:industry:39': 'Performing Arts',
                                    'urn:li:industry:40': 'Recreational Facilities & Services',
                                    'urn:li:industry:41': 'Banking',
                                    'urn:li:industry:42': 'Insurance',
                                    'urn:li:industry:43': 'Financial Services',
                                    'urn:li:industry:44': 'Real Estate',
                                    'urn:li:industry:45': 'Investment Banking',
                                    'urn:li:industry:46': 'Investment Management',
                                    'urn:li:industry:47': 'Accounting',
                                    'urn:li:industry:48': 'Construction',
                                    'urn:li:industry:49': 'Building Materials',
                                    'urn:li:industry:50': 'Architecture & Planning',
                                    'urn:li:industry:51': 'Civil Engineering',
                                    'urn:li:industry:52': 'Aviation & Aerospace',
                                    'urn:li:industry:53': 'Automotive',
                                    'urn:li:industry:54': 'Chemicals',
                                    'urn:li:industry:55': 'Machinery',
                                    'urn:li:industry:56': 'Mining & Metals',
                                    'urn:li:industry:57': 'Oil & Energy',
                                    'urn:li:industry:58': 'Shipbuilding',
                                    'urn:li:industry:59': 'Utilities',
                                    'urn:li:industry:3131': 'Mobile Games',
                                    'urn:li:industry:60': 'Textiles',
                                    'urn:li:industry:61': 'Paper & Forest Products',
                                    'urn:li:industry:62': 'Railroad Manufacture',
                                    'urn:li:industry:63': 'Farming',
                                    'urn:li:industry:64': 'Ranching',
                                    'urn:li:industry:65': 'Dairy',
                                    'urn:li:industry:66': 'Fishery',
                                    'urn:li:industry:67': 'Primary/Secondary Education',
                                    'urn:li:industry:68': 'Higher Education',
                                    'urn:li:industry:69': 'Education Management',
                                    'urn:li:industry:70': 'Research',
                                    'urn:li:industry:71': 'Military',
                                    'urn:li:industry:72': 'Legislative Office',
                                    'urn:li:industry:73': 'Judiciary',
                                    'urn:li:industry:74': 'International Affairs',
                                    'urn:li:industry:75': 'Government Administration',
                                    'urn:li:industry:76': 'Executive Office',
                                    'urn:li:industry:77': 'Law Enforcement',
                                    'urn:li:industry:78': 'Public Safety',
                                    'urn:li:industry:79': 'Public Policy',
                                    'urn:li:industry:80': 'Marketing & Advertising',
                                    'urn:li:industry:81': 'Newspapers',
                                    'urn:li:industry:82': 'Publishing',
                                    'urn:li:industry:83': 'Printing',
                                    'urn:li:industry:84': 'Information Services',
                                    'urn:li:industry:85': 'Libraries',
                                    'urn:li:industry:86': 'Environmental Services',
                                    'urn:li:industry:87': 'Package/Freight Delivery',
                                    'urn:li:industry:88': 'Individual & Family Services',
                                    'urn:li:industry:89': 'Religious Institutions',
                                    'urn:li:industry:90': 'Civic & Social Organization',
                                    'urn:li:industry:91': 'Consumer Services',
                                    'urn:li:industry:92': 'Transportation/Trucking/Railroad',
                                    'urn:li:industry:93': 'Warehousing',
                                    'urn:li:industry:94': 'Airlines/Aviation',
                                    'urn:li:industry:95': 'Maritime',
                                    'urn:li:industry:96': 'Information Technology & Services',
                                    'urn:li:industry:97': 'Market Research',
                                    'urn:li:industry:98': 'Public Relations & Communications',
                                    'urn:li:industry:99': 'Design',
                                    'urn:li:industry:100': 'Non-profit Organization Management',
                                    'urn:li:industry:101': 'Fundraising',
                                    'urn:li:industry:102': 'Program Development',
                                    'urn:li:industry:103': 'Writing & Editing',
                                    'urn:li:industry:104': 'Staffing & Recruiting',
                                    'urn:li:industry:105': 'Professional Training & Coaching',
                                    'urn:li:industry:106': 'Venture Capital & Private Equity',
                                    'urn:li:industry:107': 'Political Organization',
                                    'urn:li:industry:108': 'Translation & Localization',
                                    'urn:li:industry:109': 'Computer Games',
                                    'urn:li:industry:110': 'Events Services',
                                    'urn:li:industry:111': 'Arts & Crafts',
                                    'urn:li:industry:112': 'Electrical & Electronic Manufacturing',
                                    'urn:li:industry:113': 'Online Media',
                                    'urn:li:industry:114': 'Nanotechnology',
                                    'urn:li:industry:115': 'Music',
                                    'urn:li:industry:116': 'Logistics & Supply Chain',
                                    'urn:li:industry:117': 'Plastics',
                                    'urn:li:industry:118': 'Computer & Network Security',
                                    'urn:li:industry:119': 'Wireless',
                                    'urn:li:industry:120': 'Alternative Dispute Resolution',
                                    'urn:li:industry:121': 'Security & Investigations',
                                    'urn:li:industry:122': 'Facilities Services',
                                    'urn:li:industry:123': 'Outsourcing/Offshoring',
                                    'urn:li:industry:124': 'Health, Wellness & Fitness',
                                    'urn:li:industry:125': 'Alternative Medicine',
                                    'urn:li:industry:126': 'Media Production',
                                    'urn:li:industry:127': 'Animation',
                                    'urn:li:industry:128': 'Commercial Real Estate',
                                    'urn:li:industry:129': 'Capital Markets',
                                    'urn:li:industry:130': 'Think Tanks',
                                    'urn:li:industry:131': 'Philanthropy',
                                    'urn:li:industry:132': 'E-learning',
                                    'urn:li:industry:133': 'Wholesale',
                                    'urn:li:industry:134': 'Import & Export',
                                    'urn:li:industry:135': 'Mechanical Or Industrial Engineering',
                                    'urn:li:industry:136': 'Photography',
                                    'urn:li:industry:137': 'Human Resources',
                                    'urn:li:industry:138': 'Business Supplies & Equipment',
                                    'urn:li:industry:139': 'Mental Health Care',
                                    'urn:li:industry:140': 'Graphic Design',
                                    'urn:li:industry:141': 'International Trade & Development',
                                    'urn:li:industry:142': 'Wine & Spirits',
                                    'urn:li:industry:143': 'Luxury Goods & Jewelry',
                                    'urn:li:industry:144': 'Renewables & Environment',
                                    'urn:li:industry:145': 'Glass, Ceramics & Concrete',
                                    'urn:li:industry:146': 'Packaging & Containers',
                                    'urn:li:industry:147': 'Industrial Automation',
                                    'urn:li:industry:148': 'Government Relations',
                                    'urn:li:industry:150': 'Horticulture'
                                }
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1200,
                    height: 630
                },
                display: {
                    width: 320
                }
            },
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicVideo', 'dynamicImageDesigned', 'dynamicVideoDesigned'],
            assetFilters: {
                dynamicImage: {
                    formats: ['square', 'landscape']
                },
                dynamicImageDesigned: {
                    formats: ['square', 'landscape']
                }
            },
            multiFrame: false
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'twitter',
        adType: 'promotedAsset',
        identifier: 'twitter_promotedAsset',
        category: 'x',
        image: 'https://media-acceptance.bycape.io/account-12/upload/bcf71499-cf48-4956-a545-68097d9b6fdb_123456.jpg',
        icon: 'https://media-acceptance.bycape.io/account-12/upload/a593bdce-a20c-457a-9e1d-9c7f3411c317_xlogo.png',
        title: 'X Promoted Asset',
        description: 'A single X promoted asset (video / image)',
        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        path: 'copy',
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'text',
                                label: 'Text',
                                maxLength: 280,
                                placeholder: 'Message at the top'
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1280,
                    height: 720
                },
                display: {
                    width: 240
                }
            },
            assetTypes: ['image', 'dynamicImage', 'video', 'dynamicVideo', 'dynamicImageDesigned', 'dynamicVideoDesigned'],
            multiFrame: false
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'twitter',

        adType: 'X post',
        identifier: 'twitter_tweet',
        category: 'X',
        image: 'https://media-acceptance.bycape.io/account-12/upload/e91d609b-80b5-4582-a054-ace5808a95c1_xpost.png',
        icon: 'https://media-acceptance.bycape.io/account-12/upload/bd8465fc-6879-4fed-8b2c-4ac2f1560d75_xlogo.png',
        title: 'X post',
        description: 'A single X post',
        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        path: 'copy',
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'text',
                                label: 'Text',
                                multiline: true,
                                maxLength: 280,
                                placeholder: 'Your message'
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1280,
                    height: 720
                },
                display: {
                    width: 240
                }
            },
            assetTypes: ['image', 'dynamicImage', 'video', 'dynamicVideo', 'dynamicImageDesigned', 'dynamicVideoDesigned'],
            assetFilters: {
                dynamicImage: {
                    formats: ['square', 'newsfeed4x5', 'landscape']
                }
            },
            multiFrame: false
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'snapchat',

        adType: 'single',
        identifier: 'snapchat_singleAd',
        category: 'snapchat',
        image: 'https://media-acceptance.bycape.io/account-12/upload/b3fa95b8-194e-47b3-b77e-6c751457e336_a89b6f7f2d4f451998e7ded6ebe50865e744356020c311eaab551b496366f705Single1x.jpg',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/07fc44f0-6796-11ea-b26a-ebb09930534a_4c2f07c01db811eaa61faf6badbd46f5Snapchat.png',
        title: 'Snapchat Single ad',
        description: 'A single Snapchat ad',

        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        path: 'copy',
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'brandName',
                                label: 'Brand name',
                                placeholder: "e.g. 'Cape'",
                                tooltip: 'Applied to the upper-left corner of your snap ads',
                                maxLength: 25
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'headline',
                                label: 'Headline',
                                placeholder: 'i.e. product name, campaign slogan/tagline, tune-in date, etc',
                                tooltip: 'Applied to the upper-left corner of your snap ads.',
                                maxLength: 34
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'url',
                                tooltip: "Please include 'http://' or 'https://' in the URL",
                                label: 'Website URL',
                                validators: [
                                    {
                                        type: 'url',
                                        severity: 'error',
                                        message: 'This should be a valid url, including https://'
                                    }
                                ]
                            },
                            {
                                type: 'select',
                                model: 'cta',
                                label: 'Call to action',
                                defaultValue: 'MORE',
                                resources: ['snapchat_cta']
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1080,
                    height: 1920
                },
                display: {
                    width: 320
                }
            },
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicVideo', 'dynamicImageDesigned', 'dynamicVideoDesigned'],
            assetFilters: {
                staticAsset: {
                    useCropper: true,
                    outputWidth: 1080,
                    outputHeight: 1920
                },
                dynamicImage: {
                    formats: ['portrait', 'stories_ad', 'vertical1', 'main9x16']
                },
                dynamicVideo: {
                    formats: ['portrait', 'vertical1', 'main9x16']
                },
                dynamicImageDesigned: {
                    formats: ['portrait', 'stories_ad', 'vertical1', 'main9x16']
                },
                dynamicVideoDesigned: {
                    formats: ['portrait', 'vertical1', 'main9x16']
                }
            },
            multiFrame: false
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'snapchat',
        adType: 'story',
        identifier: 'snapchat_storiesAd',
        category: 'snapchat',
        image: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/c2e2f7c0-6e8d-11ea-b497-9d3184110a39_snapchatstoryad1x.jpg',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/cb3624e0-6796-11ea-b26a-ebb09930534a_4c2f07c01db811eaa61faf6badbd46f5Snapchat.png',
        title: 'Snapchat Stories Ad',
        description: 'A Snapchat story ad',
        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Copy',
                        items: [
                            {
                                type: 'alert',
                                alertType: 'info',
                                message: 'Click ad for detailed view: Copy is applied to the upper-left corner of your snap ads'
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'brandName',
                                label: 'Brand name',
                                placeholder: "e.g. 'Cape'",
                                tooltip: 'Applied to the upper-left corner of your snap ads',
                                maxLength: 25
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'headline',
                                label: 'Headline',
                                placeholder: 'i.e. product name, campaign slogan/tagline, tune-in date, etc',
                                maxLength: 34
                            }
                        ]
                    },
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Tile',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'tile.headline',
                                label: 'Headline',
                                placeholder: 'Your headline in the bottom-left of the tile',
                                maxLength: 55
                            },
                            {
                                type: 'assetGalleryInput',
                                fileType: 'image',
                                label: 'Logo',
                                model: 'tile.logo',
                                imageFormat: 'png',
                                sourceDataModel: 'social.library.images',
                                helperText: 'Image has to be a PNG and the size should be 993x284px',
                                format: 'Leaderboard',
                                outputWidth: 993,
                                outputHeight: 284,
                                useCropper: true
                            },
                            {
                                type: 'assetGalleryInput',
                                fileType: 'image',
                                label: 'Background image',
                                imageFormat: 'png',
                                model: 'tile.backgroundImage',
                                sourceDataModel: 'social.library.images',
                                helperText: 'Image has to be a PNG and the size should have an aspect ratio of 3:5 and a minimum size of 360x600px',
                                format: 'Skyscraper',
                                outputWidth: 720,
                                outputHeight: 1200,
                                useCropper: true
                            }
                        ]
                    }
                ]
            },
            {
                path: 'dynamicFrame',
                title: 'Copy',
                items: [
                    {
                        type: 'select',
                        model: 'cta',
                        label: 'Call to action',
                        defaultValue: 'MORE',
                        resources: ['snapchat_cta']
                    },
                    {
                        type: 'textMultiLanguage',
                        model: 'url',
                        label: 'Website URL',
                        helperText: 'Enter the URL for the web page that you want people to visit',
                        tooltip: 'Please make sure to write the full URL, including https://',
                        placeholder: "e.g. 'http://domain.com'",
                        validators: [
                            {
                                type: 'url',
                                severity: 'error',
                                message: 'This should be a valid url, including https://'
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1080,
                    height: 1920
                },
                display: {
                    width: 320
                }
            },
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicVideo', 'dynamicImageDesigned', 'dynamicVideoDesigned'],
            assetFilters: {
                staticAsset: {
                    useCropper: true,
                    outputWidth: 1080,
                    outputHeight: 1920
                },
                dynamicImage: {
                    formats: ['vertical', 'portait', 'stories', 'main9x16']
                },
                dynamicVideo: {
                    formats: ['vertical', 'portait', 'stories', 'main9x16']
                },
                dynamicImageDesigned: {
                    formats: ['vertical', 'portait', 'stories', 'main9x16']
                },
                dynamicVideoDesigned: {
                    formats: ['vertical', 'portait', 'stories', 'main9x16']
                }
            },
            multiFrame: true,
            dynamicFrames: true
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'pinterest',
        adType: 'sponsoredPin',
        identifier: 'pinterest_sponsored_pin',
        category: 'pinterest',
        image: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/48f1b420-7288-11ea-b3e0-05e1a89193ba_pinterestpromoted1x.jpg',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/9ee3ca80-6798-11ea-b26a-ebb09930534a_5ae63a401dc211ea86fb0d12c24c268bPinterestlogo.png',
        title: 'Pinterest Sponsored Pin',
        description: 'A single sponsored pin from Pinterest',
        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        path: 'copy',
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'title',
                                label: 'Title',
                                maxLength: 100,
                                placeholder: 'Title at the bottom',
                                helperText: 'If you don’t fill out a title, people will see your Pin description instead.'
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'description',
                                label: 'Description',
                                maxLength: 500,
                                helperText: 'First 50-60 characters are most likely to show up in people’s feed.',
                                placeholder: 'Your description here: important info first'
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1000,
                    height: 1500
                },
                display: {
                    width: 320
                }
            },
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicVideo', 'dynamicImageDesigned', 'dynamicVideoDesigned'],
            multiFrame: false
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'youtube',
        adType: 'videoAd',
        identifier: 'youtube_videoAd',
        category: 'youtube',
        image: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/56980210-6e90-11ea-838a-c9db71e62cfb_youtubead.jpg',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/21f60fe0-679a-11ea-b26a-ebb09930534a_63f864801dc411ea86fb0d12c24c268byoutube.png',
        title: 'Youtube Video Ad',
        description: 'A single Youtube video ad',
        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        path: 'copy',
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'displayUrl',
                                label: 'Display URL',
                                placeholder: "e.g. 'bycape.io'"
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'landingpageUrl',
                                label: 'Landing page URL'
                            },
                            {
                                type: 'divider'
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'cta',
                                label: 'Call to action',
                                maxLength: 10,
                                placeholder: 'Your CTA here'
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'headline',
                                label: 'Headline',
                                maxLength: 15,
                                placeholder: 'Your headline here'
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'longHeadline',
                                label: 'Long headline',
                                maxLength: 90,
                                placeholder: 'Your headline here'
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'description',
                                label: 'Description',
                                maxLength: 70,
                                placeholder: 'Your description here'
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1200,
                    height: 630
                },
                display: {
                    width: 320
                }
            },
            assetTypes: ['video', 'dynamicVideo', 'dynamicVideoDesigned', 'dynamicAfterEffects'],
            multiFrame: false
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'facebook',
        adType: 'storiesPost',
        identifier: 'facebook_storiesPost',
        category: 'facebook',
        image: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/36eac0e0-6e8d-11ea-b497-9d3184110a39_facebookstoriespost1x.jpg',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/bbd50130-6915-11ea-9509-510dd70866f4_554f8740676311eaa0845b851f9a3c4773edddd01d9611eaa088dd3615efadcbFacebook.png',
        title: 'Facebook Stories Post',
        description: 'A Facebook stories post',

        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Copy',
                        items: [
                            {
                                type: 'switch',
                                model: 'isReel',
                                label: 'Post as a reel'
                            },
                            {
                                condition: 'blockData.isReel',
                                type: 'textMultiLanguage',
                                model: 'message',
                                label: 'Text',
                                multiline: true
                            }
                        ]
                    }
                ]
            },
            {
                path: 'dynamicFrame',
                title: 'Copy',
                items: []
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1080,
                    height: 1920
                },
                display: {
                    width: 320
                }
            },
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicVideo', 'dynamicImageDesigned', 'dynamicVideoDesigned', 'dynamicAfterEffects'],
            multiFrame: true,
            dynamicFrames: true
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'instagram',

        adType: 'carouselPost',
        identifier: 'instagram_carouselPost',
        category: 'instagram',
        image: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/49e3c750-6e8d-11ea-b497-9d3184110a39_instagramcarouselpost1x.jpg',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/38c987b0-6916-11ea-9509-510dd70866f4_540b39e0678311eab376b3c3bf48da255c7d28901daf11eaa61faf6badbd46f5Instagram.png',
        title: 'Instagram Carousel Post',
        description: 'An Instagram carousel post',
        interfaceSetup: [
            {
                path: 'general',
                title: 'Copy',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        path: 'copy',
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'text',
                                label: 'Text',
                                multiline: true,
                                maxLength: 2200,
                                placeholder: 'Your message at the bottom',
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                path: 'dynamicFrame',
                title: 'Copy',
                items: []
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1200,
                    height: 1200
                },
                display: {
                    width: 320
                }
            },
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicVideo', 'dynamicImageDesigned', 'dynamicVideoDesigned'],
            multiFrame: true,
            dynamicFrames: true
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'instagram',

        adType: 'storiesPost',
        identifier: 'instagram_storiesPost',
        category: 'instagram',
        image: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/6b6fbaf0-6e8d-11ea-b497-9d3184110a39_instagramstorypost1x.jpg',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/e11bbd70-6916-11ea-9509-510dd70866f4_540b39e0678311eab376b3c3bf48da255c7d28901daf11eaa61faf6badbd46f5Instagram.png',
        title: 'Instagram Stories Post',
        description: 'An Instagram stories post',
        version: null,
        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Copy',
                        items: [
                            {
                                type: 'alert',
                                alertType: 'info',
                                message: 'Please note that Instagram stories need to be created manually. You cannot publish directly from Cape'
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'message',
                                label: 'Primary text',
                                multiline: true,
                                maxLength: 2200,
                                placeholder: 'Your message at the bottom',
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                path: 'dynamicFrame',
                title: 'Copy',
                items: []
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1080,
                    height: 1920
                },
                display: {
                    width: 320
                }
            },
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicVideo', 'dynamicImageDesigned', 'dynamicVideoDesigned', 'dynamicAfterEffects'],
            multiFrame: true,
            dynamicFrames: true
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'twitter',
        adType: 'promotedText',
        identifier: 'twitter_promotedText',
        category: 'x',
        image: 'https://media-acceptance.bycape.io/account-12/upload/ea394f02-490a-44c7-a9d3-d3fbd5778b45_xtet.jpg',
        icon: 'https://media-acceptance.bycape.io/account-12/upload/265e5729-86f2-401d-8408-7f811b843ad0_xlogo.png',
        title: 'X Promoted Text',
        description: 'A single X promoted text ad',

        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        path: 'copy',
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'text',
                                label: 'Text',
                                maxLength: 280,
                                placeholder: 'Your message'
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            multiFrame: false
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'twitter',
        adType: 'multiImage',
        identifier: 'twitter_multiImage',
        category: 'X',
        image: 'https://media-acceptance.bycape.io/account-12/upload/47cd02df-53f8-440f-8e22-425ce5a44d6c_Frame1.png',
        icon: 'https://media-acceptance.bycape.io/account-12/upload/eb3b63e6-3af9-4224-bc4a-09987b871a3e_xlogo.png',
        title: 'X Multi Image post',
        description: 'A multi image X post',
        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        path: 'copy',
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'text',
                                label: 'Text',
                                maxLength: 280,
                                placeholder: 'Your message'
                            }
                        ]
                    }
                ]
            },
            {
                path: 'dynamicFrame',
                title: 'Copy',
                items: []
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1200,
                    height: 1200
                },
                display: {
                    width: 240
                }
            },
            assetTypes: ['image', 'dynamicImage', 'dynamicImageDesigned'],
            multiFrame: true,
            dynamicFrames: true,
            maxFrames: 4
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'twitter',
        adType: 'websiteCard',
        identifier: 'twitter_websiteCard',
        category: 'X',
        image: 'https://media-acceptance.bycape.io/account-12/upload/757e3fbd-7aaf-4574-8de5-9a14e9dcf4fe_xwebsitecard.jpg',
        icon: 'https://media-acceptance.bycape.io/account-12/upload/041b8062-e245-41bc-9aef-72839a628cea_xlogo.png',
        title: 'X Website Card',
        description: 'A single X website card (image / video)',

        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        path: 'copy',
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'text',
                                label: 'Text',
                                multiline: true,
                                maxLength: 280,
                                placeholder: 'Your message'
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'title',
                                label: 'Title',
                                multiline: true,
                                placeholder: 'Your message here',
                                maxLength: 70,
                                tooltip: 'Limiting the description to 50 characters should ensure that truncation won’t occur across most devices.'
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'displayUrl',
                                label: 'Display Url',
                                placeholder: "e.g. 'bycape.io'"
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1280,
                    height: 720
                },
                display: {
                    width: 240
                }
            },
            assetTypes: ['image', 'dynamicImage', 'video', 'dynamicVideo', 'dynamicImageDesigned', 'dynamicVideoDesigned'],
            multiFrame: false
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'pinterest',

        adType: 'carouselAd',

        identifier: 'pinterest_carousel_ad',
        category: 'pinterest',
        image: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/f6480500-8559-11ea-aa60-332112fa6111_Promotedcarousel.png',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/bf8e2410-75b7-11ea-87d5-fd87816407d1_9ee3ca80679811eab26aebb09930534a5ae63a401dc211ea86fb0d12c24c268bPinterestlogo.png',
        title: 'Pinterest Carousel Ad',
        description: 'A Pinterest Carousel Ad',

        interfaceSetup: [
            {
                path: 'general',
                title: 'Copy',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        path: 'copy',
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'title',
                                label: 'Title',
                                maxLength: 100,
                                placeholder: 'Your title at the bottom',
                                helperText: 'If you don’t fill out a title, people will see description instead.'
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'description',
                                label: 'Description',
                                maxLength: 500,
                                helperText: 'First 50-60 characters are most likely to show up in people’s feed.',
                                placeholder: 'Your description: important info first'
                            }
                        ]
                    }
                ]
            },
            {
                path: 'dynamicFrame',
                title: 'Asset',
                items: [{}]
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1080,
                    height: 1920
                },
                display: {
                    width: 320
                }
            },
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicVideo', 'dynamicImageDesigned', 'dynamicVideoDesigned'],
            multiFrame: true,
            dynamicFrames: true
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'youtube',
        adType: 'video',
        identifier: 'youtube_video',
        category: 'youtube',
        image: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/05e6a430-913f-11ea-ba1a-1f561a84616a_image4.png',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/e6c08670-9111-11ea-aa58-bfdc3edff02d_21f60fe0679a11eab26aebb09930534a63f864801dc411ea86fb0d12c24c268byoutube.png',
        title: 'Youtube video',
        description: 'A normal Youtube video post',

        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        path: 'copy',
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'title',
                                label: 'Title',
                                placholder: 'Title of the video'
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'description',
                                label: 'Description',
                                tooltip:
                                    'Well-written descriptions with the right keywords can boost views and watch time because they help your video to appear in search results.',
                                helperText: 'Writing descriptions with keywords can help viewers to find your videos'
                            },
                            {
                                type: 'divider'
                            },
                            {
                                type: 'autocomplete',
                                inputType: 'taglist',
                                model: 'tags',
                                label: 'Tags'
                            },
                            {
                                type: 'divider'
                            },
                            {
                                type: 'select',
                                model: 'categoryId',
                                label: 'Category ID',
                                defaultValue: '24',
                                options: {
                                    '1': 'Film & Animation',
                                    '2': 'Autos & Vehicles',
                                    '10': 'Music',
                                    '15': 'Pets & Animals',
                                    '17': 'Sports',
                                    '19': 'Travel & Events',
                                    '20': 'Gaming',
                                    '22': 'People & Blogs',
                                    '23': 'Comedy',
                                    '24': 'Entertainment',
                                    '25': 'News & Politics',
                                    '26': 'Howto & Style',
                                    '27': 'Education',
                                    '28': 'Science & Technology',
                                    '29': 'Nonprofits & Activism'
                                }
                            },
                            {
                                type: 'divider'
                            },
                            {
                                type: 'radioList',
                                model: 'privacyStatus',
                                label: 'Privacy status',
                                defaultValue: 'unlisted',
                                options: {
                                    private: 'Private',
                                    public: 'Public',
                                    unlisted: 'Unlisted'
                                }
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            formats: ['general'],
            assetSizes: {
                output: {
                    width: 1200,
                    height: 630
                },
                display: {
                    width: 320
                }
            },
            assetTypes: ['video', 'dynamicVideo', 'dynamicVideoDesigned', 'dynamicAfterEffects'],
            multiFrame: false
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'facebook',

        adType: 'multipleAssetsPost',
        identifier: 'facebook_multiple_post',
        category: 'facebook',
        image: 'https://media-acceptance.bycape.io/account-12/upload/25675f82-5116-446a-beb8-1b67e833ee00_24675fa06e8d11eab4979d3184110a39facebookpost1x.jpg',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/ef4387f0-a199-11ea-b859-91c028ff2406_928f1280676111eaa0845b851f9a3c4773edddd01d9611eaa088dd3615efadcbFacebook.png',
        title: 'Facebook Multiple Assets Post',
        description: 'Facebook post with multiple assets',
        version: null,
        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'message',
                                label: 'Text',
                                multiline: true,
                                tooltip: 'Text over 125 characters is allowed, but may be truncated',
                                maxLength: 2200
                            }
                        ]
                    }
                ]
            },
            {
                path: 'dynamicFrame',
                title: 'Copy',
                items: []
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1200,
                    height: 1200
                },
                display: {
                    width: 320
                }
            },
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicVideo'],
            multiFrame: true,
            dynamicFrames: true
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'pinterest',

        adType: 'pin',
        identifier: 'pinterest_pin',
        category: 'pinterest',
        image: '',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/eda7edc0-aee9-11ea-b007-cfd0e22c69cc_9ee3ca80679811eab26aebb09930534a5ae63a401dc211ea86fb0d12c24c268bPinterestlogo.png',
        title: 'Pinterest Pin',
        description: 'A single Pinterest pin',
        version: null,
        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        path: 'copy',
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'description',
                                label: 'Description',
                                helperText: 'Description is not visible in this preview',
                                maxLength: 500,
                                placeholder: 'Your description: important info first',
                                tooltip: 'The first 50-60 characters are most likely to show up in people’s feeds, so put your most important info first.'
                            },
                            {
                                type: 'text',
                                model: 'link',
                                label: 'Link',
                                helperText: 'Enter the URL for the web page that you want people to visit',
                                tooltip: 'Please make sure to write the full URL, including https://',
                                placeholder: "e.g. 'https://domain.com",
                                validators: [
                                    {
                                        type: 'url',
                                        severity: 'error',
                                        message: 'This should be a valid url, including https://'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1000,
                    height: 1500
                },
                display: {
                    width: 320
                }
            },
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicVideo', 'dynamicImageDesigned', 'dynamicVideoDesigned'],
            multiFrame: false
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'tiktok',

        adType: 'videoAd',
        identifier: 'tiktok_videoAd',
        category: 'tiktok',
        image: 'https://storage.googleapis.com/cape-media/account-12/upload/8e5e9240-3f78-11eb-a382-b1e53a418bef_Home2x.png',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/5490dbc0-29ae-11eb-95e6-5f3a04b42ce6_SocialmediaTiktok512.png',
        title: 'TikTok Video Ad',
        description: 'A video ad for TikTok',
        version: null,
        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'displayName',
                                label: 'Display name',
                                placeholder: "e.g. 'Cape'",
                                maxLength: 30,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 30,
                                        min: 1,
                                        severity: 'warning',
                                        message: 'Please enter your display name'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'adText',
                                label: 'Ad text',
                                maxLength: 100,
                                minLength: 12,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 100,
                                        min: 12,
                                        severity: 'error',
                                        message: 'Ad text length should be 12-100 long and cannot contain emoji'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'landingPageUrl',
                                label: 'Landing page URL',
                                validators: [
                                    {
                                        type: 'url',
                                        severity: 'error',
                                        message: 'This should contain a valid URL'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1080,
                    height: 1920
                },
                display: {
                    width: 320
                }
            },
            assetTypes: ['video', 'dynamicVideo', 'dynamicVideoDesigned', 'dynamicAfterEffects'],
            multiFrame: false
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'facebook',

        adType: 'generic',
        identifier: 'facebook_generic',
        category: 'facebook',
        image: 'https://storage.googleapis.com/cape-media/account-12/upload/449181f0-3883-11eb-a46b-73b06c2c5ece_7b492a806e9011ea838ac9db71e62cfbfacebooklinkad1x.jpg',
        icon: 'https://storage.googleapis.com/cape-media/account-12/upload/42e7c990-3883-11eb-a46b-73b06c2c5ece_73edddd01d9611eaa088dd3615efadcbFacebook.png',
        title: 'Meta Generic ad ',
        description: 'A generic ad for Facebook, Instagram and Messenger',

        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Copy',
                        items: [
                            {
                                type: 'itemList',
                                multiLanguage: true,
                                model: 'copy.message',
                                label: 'Text (multiple options possible)',
                                multiline: true,
                                maxLength: 2200
                            },
                            {
                                type: 'divider'
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'copy.name',
                                label: 'Headline',
                                validators: [
                                    {
                                        type: 'length',
                                        max: 25,
                                        severity: 'warning',
                                        message: 'Text over 25 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'copy.description',
                                label: 'Description',
                                validators: [
                                    {
                                        type: 'length',
                                        max: 20,
                                        severity: 'warning',
                                        message: 'Text over 20 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'divider'
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'copy.link',
                                label: 'URL',
                                validators: [
                                    {
                                        type: 'url',
                                        severity: 'error',
                                        message: 'This should be a valid url, including https://'
                                    }
                                ],
                                helperText: 'Enter the URL for the web page that you want people to visit',
                                tooltip: 'Please make sure to write the full URL, including https://',
                                placeholder: "e.g. 'https://domain.com'"
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'copy.caption',
                                placeholder: 'Show a shortened link',
                                label: 'Display URL (optional)'
                            },
                            {
                                type: 'divider'
                            },
                            {
                                type: 'select',
                                model: 'copy.cta',
                                label: 'Call to action',
                                defaultValue: 'OPEN_LINK',
                                resources: ['facebook_cta']
                            }
                        ]
                    },
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Publishing',
                        items: [
                            {
                                type: 'checkboxList',
                                model: 'publishing.publisher_platforms',
                                label: 'Publisher platforms',
                                options: [
                                    {
                                        key: 'facebook',
                                        value: 'Facebook'
                                    },
                                    {
                                        key: 'instagram',
                                        value: 'Instagram'
                                    },
                                    {
                                        key: 'messenger',
                                        value: 'Messenger'
                                    },
                                    {
                                        key: 'audience_network',
                                        value: 'Audience Network'
                                    }
                                ]
                            },
                            {
                                type: 'checkboxList',
                                model: 'publishing.facebook_positions',
                                condition: "blockData.publishing.publisher_platforms.value.includes('facebook')",
                                label: 'Facebook positions',
                                options: {
                                    feed: 'Facebook feed',
                                    right_hand_column: 'Facebook Right Hand Column',
                                    instant_article: 'Instant article',
                                    marketplace: 'Marketplace',
                                    video_feeds: 'Video feeds',
                                    story: 'Search'
                                }
                            },
                            {
                                type: 'checkboxList',
                                model: 'publishing.instagram_positions',
                                condition: "blockData.publishing.publisher_platforms.value.includes('instagram')",
                                label: 'Instagram positions',
                                options: {
                                    stream: 'Instagram stream',
                                    story: 'Instagram story',
                                    explore: 'Instagram explore'
                                }
                            },
                            {
                                type: 'checkboxList',
                                model: 'publishing.audience_network_positions',
                                condition: "blockData.publishing.publisher_platforms.value.includes('audience_network')",
                                label: 'Audience network positions',
                                options: {
                                    classic: 'Classic',
                                    instream_video: 'Instream video',
                                    rewarded_video: 'Rewarded video'
                                }
                            },
                            {
                                type: 'checkboxList',
                                model: 'publishing.messenger_positions',
                                condition: "blockData.publishing.publisher_platforms.value.includes('messenger')",
                                label: 'Messenger positions',
                                options: {
                                    messenger_home: 'Messenger home',
                                    sponsored_messages: 'Sponsored messages',
                                    story: 'Stories'
                                }
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {
                output: {
                    width: 1200,
                    height: 630
                },
                display: {
                    width: 320
                }
            },
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicVideo', 'dynamicImageDesigned', 'dynamicVideoDesigned'],
            multiFrame: false,
            multiAsset: true
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'facebook',
        adType: 'dynamic',
        identifier: 'meta_dynamic_ad',
        category: 'facebook',
        image: 'https://storage.googleapis.com/cape-shared-media-acc/account-12/upload/09dbdb39-6f8a-46d0-99ca-1101c3b4a40f_9a16c1706e9011ea838ac9db71e62cfbinstagramlinkad1x.jpeg',
        icon: 'https://storage.googleapis.com/cape-media/account-12/upload/42e7c990-3883-11eb-a46b-73b06c2c5ece_73edddd01d9611eaa088dd3615efadcbFacebook.png',
        title: 'Meta Dynamic Ad',
        description: 'Create a dynamic ad for the different meta channels with different visual formats.',
        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'message',
                                label: 'Primary text',
                                multiline: true,
                                placeholder: 'Your message at the top',
                                maxLength: 2200,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'message2',
                                label: 'Primary text variant 2',
                                condition: 'blockData.message',
                                multiline: true,
                                placeholder: 'Your message at the top',
                                maxLength: 2200,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'message3',
                                label: 'Primary text variant 3',
                                condition: 'blockData.message2',
                                multiline: true,
                                placeholder: 'Your message at the top',
                                maxLength: 2200,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'message4',
                                label: 'Primary text variant 4',
                                condition: 'blockData.message3',
                                multiline: true,
                                placeholder: 'Your message at the top',
                                maxLength: 2200,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'message5',
                                label: 'Primary text variant 5',
                                condition: 'blockData.message4',
                                multiline: true,
                                placeholder: 'Your message at the top',
                                maxLength: 2200,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'divider'
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'headline',
                                label: 'Headline',
                                validators: [
                                    {
                                        type: 'length',
                                        max: 40,
                                        severity: 'warning',
                                        message: 'Text over 40 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                condition: 'blockData.headline',
                                model: 'headline2',
                                label: 'Headline 2',
                                validators: [
                                    {
                                        type: 'length',
                                        max: 40,
                                        severity: 'warning',
                                        message: 'Text over 40 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                condition: 'blockData.headline2',
                                model: 'headline3',
                                label: 'Headline 3',
                                validators: [
                                    {
                                        type: 'length',
                                        max: 40,
                                        severity: 'warning',
                                        message: 'Text over 40 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                condition: 'blockData.headline3',
                                model: 'headline4',
                                label: 'Headline 4',
                                validators: [
                                    {
                                        type: 'length',
                                        max: 40,
                                        severity: 'warning',
                                        message: 'Text over 40 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                condition: 'blockData.headline4',
                                model: 'headline5',
                                label: 'Headline 5',
                                validators: [
                                    {
                                        type: 'length',
                                        max: 40,
                                        severity: 'warning',
                                        message: 'Text over 40 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'description',
                                label: 'Ad description in Meta',
                                helperText: 'Please make sure description is added',
                                validators: [
                                    {
                                        type: 'length',
                                        max: 20,
                                        severity: 'warning',
                                        message: 'Text over 20 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'divider'
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'link',
                                label: 'URL',
                                validators: [
                                    {
                                        type: 'url',
                                        severity: 'error',
                                        message: 'This should be a valid url, including https://'
                                    }
                                ],
                                helperText: 'Enter the URL for the web page that you want people to visit',
                                tooltip: 'Please make sure to write the full URL, including https://',
                                placeholder: "e.g. 'https://domain.com'"
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'caption',
                                placeholder: 'Show a shortened link',
                                label: 'Display URL (optional)'
                            },
                            {
                                type: 'divider'
                            },
                            {
                                type: 'select',
                                model: 'cta',
                                label: 'Call to action',
                                defaultValue: 'OPEN_LINK',
                                resources: ['facebook_cta']
                            }
                        ]
                    },
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Adset Placements',
                        items: [
                            {
                                type: 'checkboxList',
                                model: 'publisher_platforms',
                                label: 'Platforms',
                                options: {
                                    facebook: 'Facebook',
                                    instagram: 'Instagram',
                                    messenger: 'Messenger'
                                }
                            },
                            {
                                type: 'checkboxList',
                                model: 'facebook_positions',
                                label: 'Facebook placements',
                                condition: "blockData.publisher_platforms.value.includes('facebook')",
                                options: {
                                    feed: 'Feed',
                                    right_hand_column: 'Right hand column',
                                    marketplace: 'Marketplace',
                                    video_feeds: 'Video feeds',
                                    instant_article: 'Instant article',
                                    search: 'Search',
                                    story: 'Story',
                                    facebook_reels: 'Facebook Reels',
                                    profile_feed: 'Profile feed',
                                    instream_video: 'Facebook in-stream videos',
                                    facebook_reels_overlay: 'Ads on Facebook reels'
                                }
                            },
                            {
                                type: 'checkboxList',
                                model: 'instagram_positions',
                                label: 'Instagram placements',
                                condition: "blockData.publisher_platforms.value.includes('instagram')",
                                options: {
                                    stream: 'Stream',
                                    story: 'Story',
                                    explore: 'Explore',
                                    explore_home: 'Explore home',
                                    reels: 'Reels',
                                    profile_feed: 'Profile feed',
                                    ig_search: 'Instagram search results',
                                    profile_reels: 'Instagram profile reels'
                                }
                            },
                            {
                                type: 'checkboxList',
                                model: 'messenger_positions',
                                label: 'Messenger placements',
                                condition: "blockData.publisher_platforms.value.includes('messenger')",
                                options: {
                                    messenger_home: 'Messenger home',
                                    story: 'Story'
                                }
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {
                display: {
                    width: 320
                }
            },
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicAfterEffects', 'dynamicVideo', 'dynamicImageDesigned', 'dynamicVideoDesigned'],
            multiFrame: false,
            dynamicFrames: false
        },
        defaultData: {
            publisher_platforms: {
                value: ['facebook', 'instagram', 'messenger']
            },
            facebook_positions: {
                value: [
                    'feed',
                    'right_hand_column',
                    'marketplace',
                    'video_feeds',
                    'instant_article',
                    'search',
                    'story',
                    'facebook_reels',
                    'profile_feed',
                    'instream_video',
                    'facebook_reels_overlay'
                ]
            },
            instagram_positions: {
                value: ['stream', 'story', 'explore', 'explore_home', 'reels', 'profile_feed', 'ig_search', 'profile_reels']
            },
            messenger_positions: {
                value: ['messenger_home', 'story']
            }
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'facebook',

        adType: 'dynamicCarousel',
        identifier: 'meta_dynamic_ad_carousel',
        category: 'facebook',
        image: 'https://storage.googleapis.com/cape-shared-media-acc/account-12/upload/353084c1-3836-436a-8113-02f2c5431393_3fce73006e8d11eab4979d3184110a39instagramcarouselad1x.jpg',
        icon: 'https://storage.googleapis.com/cape-media/account-12/upload/42e7c990-3883-11eb-a46b-73b06c2c5ece_73edddd01d9611eaa088dd3615efadcbFacebook.png',
        title: 'Meta Dynamic Carousel Ad',
        description: 'Create a dynamic carousel ad for the different meta channels with different visual formats.',
        version: null,
        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'message',
                                label: 'Text',
                                multiline: true,
                                placeholder: 'Your message at the top',
                                maxLength: 2200,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'divider'
                            },
                            {
                                type: 'select',
                                model: 'cta',
                                label: 'Call to action',
                                defaultValue: 'OPEN_LINK',
                                resources: ['facebook_cta']
                            }
                        ]
                    },
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Placements',
                        items: [
                            {
                                type: 'checkboxList',
                                model: 'publisher_platforms',
                                label: 'Platforms',
                                options: {
                                    facebook: 'Facebook',
                                    instagram: 'Instagram',
                                    messenger: 'Messenger'
                                }
                            },
                            {
                                type: 'checkboxList',
                                model: 'facebook_positions',
                                label: 'Facebook placements',
                                condition: "blockData.publisher_platforms.value.includes('facebook')",
                                options: {
                                    feed: 'Feed',
                                    right_hand_column: 'Right hand column',
                                    marketplace: 'Marketplace',
                                    video_feeds: 'Video feeds',
                                    instant_article: 'Instant article',
                                    search: 'Search',
                                    story: 'Story',
                                    facebook_reels: 'Facebook Reels',
                                    profile_feed: 'Profile feed',
                                    instream_video: 'Facebook in-stream videos',
                                    facebook_reels_overlay: 'Ads on Facebook reels'
                                }
                            },
                            {
                                type: 'checkboxList',
                                model: 'instagram_positions',
                                label: 'Instagram placements',
                                condition: "blockData.publisher_platforms.value.includes('instagram')",
                                options: {
                                    stream: 'Stream',
                                    story: 'Story',
                                    explore: 'Explore',
                                    explore_home: 'Explore home',
                                    reels: 'Reels',
                                    profile_feed: 'Profile feed',
                                    ig_search: 'Instagram search results',
                                    profile_reels: 'Instagram profile reels'
                                }
                            },
                            {
                                type: 'checkboxList',
                                model: 'messenger_positions',
                                label: 'Messenger placements',
                                condition: "blockData.publisher_platforms.value.includes('messenger')",
                                options: {
                                    messenger_home: 'Messenger home',
                                    story: 'Story'
                                }
                            }
                        ]
                    }
                ]
            },
            {
                path: 'dynamicFrame',
                title: 'Copy',
                items: [
                    {
                        type: 'textMultiLanguage',
                        model: 'headline',
                        label: 'Headline',
                        validators: [
                            {
                                type: 'length',
                                max: 40,
                                severity: 'warning',
                                message:
                                    'A unique headline for each frame is required for your ad to publish. Text over 40 characters is allowed, but may be truncated.'
                            }
                        ]
                    },
                    {
                        type: 'textMultiLanguage',
                        model: 'description',
                        label: 'Ad description in Meta',
                        helperText: 'Please, add a description',
                        validators: [
                            {
                                type: 'length',
                                max: 20,
                                severity: 'warning',
                                message:
                                    'A description for each frame is required for your ad to publish. Text over 20 characters is allowed, but may be truncated'
                            }
                        ]
                    },
                    {
                        type: 'textMultiLanguage',
                        model: 'link',
                        label: 'Link',
                        helperText: 'Enter the URL for the web page that you want people to visit',
                        tooltip: 'Please make sure to write the full URL, including https://',
                        placeholder: "e.g. 'https://domain.com",
                        validators: [
                            {
                                type: 'url',
                                severity: 'error',
                                message: 'This should be a valid url, including https://'
                            }
                        ]
                    },
                    {
                        type: 'textMultiLanguage',
                        model: 'caption',
                        placeholder: 'Show a shortened link',
                        label: 'Display URL (optional)'
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {},
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicImageDesigned', 'dynamicVideoDesigned', 'dynamicVideo'],
            groupKey: 'test',
            multiFrame: true,
            dynamicFrames: true
        },
        defaultData: {
            publisher_platforms: {
                value: ['facebook', 'instagram', 'messenger']
            },
            facebook_positions: {
                value: [
                    'feed',
                    'right_hand_column',
                    'marketplace',
                    'video_feeds',
                    'instant_article',
                    'search',
                    'story',
                    'facebook_reels',
                    'profile_feed',
                    'instream_video',
                    'facebook_reels_overlay'
                ]
            },
            instagram_positions: {
                value: ['stream', 'story', 'explore', 'explore_home', 'reels', 'profile_feed', 'ig_search', 'profile_reels']
            },
            messenger_positions: {
                value: ['messenger_home', 'story']
            }
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'facebook',
        adType: 'dynamic',
        identifier: 'meta_dynamic_ad_optus',
        category: 'facebook',
        image: 'https://media-acceptance.bycape.io/account-95/upload/82d73f19-3dd3-45b7-b3f6-9f28a5934453_09dbdb396f8a46d099ca1101c3b4a40f9a16c1706e9011ea838ac9db71e62cfbinstagramlinkad1x.jpeg',
        icon: 'https://storage.googleapis.com/cape-media/account-12/upload/42e7c990-3883-11eb-a46b-73b06c2c5ece_73edddd01d9611eaa088dd3615efadcbFacebook.png',
        title: 'Meta Dynamic Ad Optus',
        description: 'Create a dynamic ad for the different meta channels with different visual formats.',

        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'messageOption1',
                                label: 'Primary text variant 1',
                                condition: "editor.type=='concept'",
                                multiline: true,
                                placeholder: 'Your message at the top',
                                maxLength: 2200,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'messageOption2',
                                label: 'Primary text variant 2',
                                condition: "editor.type=='concept'",
                                multiline: true,
                                placeholder: 'Your message at the top',
                                maxLength: 2200,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'messageOption3',
                                label: 'Primary text variant 3',
                                condition: "editor.type=='concept'",
                                multiline: true,
                                placeholder: 'Your message at the top',
                                maxLength: 2200,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'messageOption4',
                                label: 'Primary text variant 4',
                                multiline: true,
                                condition: "editor.type=='concept'",
                                placeholder: 'Your message at the top',
                                maxLength: 2200,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'messageOption5',
                                label: 'Primary text variant 5',
                                multiline: true,
                                condition: "editor.type=='concept'",
                                placeholder: 'Your message at the top',
                                maxLength: 2200,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'hidden',
                                parseValue: 'true',
                                model: 'message.multilanguage'
                            },
                            {
                                type: 'select',
                                model: 'message.EN.value',
                                label: 'Primary text select',
                                placeholder: 'Your message at the top',
                                options: [
                                    {
                                        key: '{{{[[blockModel]].messageOption1.EN.value}}}',
                                        value: '{{{[[blockModel]].messageOption1.EN.value}}}'
                                    },
                                    {
                                        key: '{{{[[blockModel]].messageOption2.EN.value}}}',
                                        value: '{{{[[blockModel]].messageOption2.EN.value}}}'
                                    },
                                    {
                                        key: '{{{[[blockModel]].messageOption3.EN.value}}}',
                                        value: '{{{[[blockModel]].messageOption3.EN.value}}}'
                                    },
                                    {
                                        key: '{{{[[blockModel]].messageOption4.EN.value}}}',
                                        value: '{{{[[blockModel]].messageOption4.EN.value}}}'
                                    },
                                    {
                                        key: '{{{[[blockModel]].messageOption5.EN.value}}}',
                                        value: '{{{[[blockModel]].messageOption5.EN.value}}}'
                                    }
                                ]
                            },
                            {
                                type: 'divider'
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'headline',
                                readOnly: "{{{editor.type!='concept'}}}",
                                label: 'Headline',
                                validators: [
                                    {
                                        type: 'length',
                                        max: 40,
                                        severity: 'warning',
                                        message: 'Text over 40 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'description',
                                readOnly: "{{{editor.type!='concept'}}}",
                                label: 'Description',
                                validators: [
                                    {
                                        type: 'length',
                                        max: 20,
                                        severity: 'warning',
                                        message: 'Text over 20 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'divider'
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'link',
                                label: 'URL',
                                validators: [
                                    {
                                        type: 'url',
                                        severity: 'error',
                                        message: 'This should be a valid url, including https://'
                                    }
                                ],
                                helperText: 'Enter the URL for the web page that you want people to visit',
                                tooltip: 'Please make sure to write the full URL, including https://',
                                placeholder: "e.g. 'https://domain.com'"
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'caption',
                                condition: "editor.type=='concept'",
                                placeholder: 'Show a shortened link',
                                defaultValue: 'optus.com.au',
                                helperText: 'Display url must have .com.au, e.g. optus.com.au',
                                label: 'Display URL (optional)'
                            },
                            {
                                type: 'divider'
                            },
                            {
                                type: 'select',
                                model: 'cta',
                                condition: "editor.type=='concept'",
                                label: 'Call to action',
                                defaultValue: 'LEARN_MORE',
                                options: {
                                    CALL_NOW: 'Call now',
                                    CONTACT_US: 'Contact us',
                                    LEARN_MORE: 'Learn more',
                                    GET_OFFER: 'Get offer',
                                    SIGN_UP: 'Sign up',
                                    SHOP_NOW: 'Shop now'
                                }
                            }
                        ]
                    },
                    {
                        type: 'subSection',
                        opened: true,
                        condition: "editor.type=='concept'",
                        title: 'Adset Placements',
                        items: [
                            {
                                type: 'checkboxList',
                                model: 'publisher_platforms',
                                label: 'Platforms',
                                options: {
                                    facebook: 'Facebook',
                                    instagram: 'Instagram',
                                    messenger: 'Messenger'
                                }
                            },
                            {
                                type: 'checkboxList',
                                model: 'facebook_positions',
                                label: 'Facebook placements',
                                condition: "blockData.publisher_platforms.value.includes('facebook')",
                                options: {
                                    feed: 'Feed',
                                    right_hand_column: 'Right hand column',
                                    marketplace: 'Marketplace',
                                    video_feeds: 'Video feeds',
                                    instant_article: 'Instant article',
                                    search: 'Search',
                                    story: 'Story',
                                    facebook_reels: 'Facebook Reels',
                                    profile_feed: 'Profile feed',
                                    instream_video: 'Facebook in-stream videos',
                                    facebook_reels_overlay: 'Ads on Facebook reels'
                                }
                            },
                            {
                                type: 'checkboxList',
                                model: 'instagram_positions',
                                label: 'Instagram placements',
                                condition: "blockData.publisher_platforms.value.includes('instagram')",
                                options: {
                                    stream: 'Stream',
                                    story: 'Story',
                                    explore: 'Explore',
                                    explore_home: 'Explore home',
                                    reels: 'Reels',
                                    profile_feed: 'Profile feed',
                                    ig_search: 'Instagram search results',
                                    profile_reels: 'Instagram profile reels'
                                }
                            },
                            {
                                type: 'checkboxList',
                                model: 'messenger_positions',
                                label: 'Messenger placements',
                                condition: "blockData.publisher_platforms.value.includes('messenger')",
                                options: {
                                    messenger_home: 'Messenger home',
                                    story: 'Story'
                                }
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {
                display: {
                    width: 320
                }
            },
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicAfterEffects', 'dynamicVideo', 'dynamicImageDesigned', 'dynamicVideoDesigned'],
            multiFrame: false,
            dynamicFrames: false
        },
        defaultData: {
            publisher_platforms: {
                value: ['facebook', 'instagram', 'messenger']
            },
            facebook_positions: {
                value: ['feed', 'right_hand_column', 'marketplace', 'video_feeds', 'instant_article', 'search', 'story', 'facebook_reels']
            },
            instagram_positions: {
                value: ['stream', 'story', 'explore', 'explore_home', 'reels', 'profile_feed']
            },
            messenger_positions: {
                value: ['messenger_home', 'story']
            }
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'facebook',
        adType: 'dynamicCarousel',
        identifier: 'meta_dynamic_carousel_ad_optus',
        image: 'https://storage-acceptance.bycape.io/account-95/upload/1daaf3e8-2787-4085-bc78-2480b135656d_353084c13836436a811302f2c54313933fce73006e8d11eab4979d3184110a39instagramcarouselad1x.jpg',
        icon: 'https://storage-acceptance.bycape.io/account-95/upload/0600f5e5-4e02-49f2-9e15-a053281beb44_7515a72a521245d3a29d43fc58aa6643540b39e0678311eab376b3c3bf48da255c7d28901daf11eaa61faf6badbd46f5Instagram.png',
        title: 'Meta Dynamic Carousel Optus',
        description: 'Meta Dynamic Carousel for Optus',
        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'messageOption1',
                                label: 'Primary text variant 1',
                                condition: "editor.type=='concept'",
                                multiline: true,
                                placeholder: 'Your message at the top',
                                maxLength: 2200,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'messageOption2',
                                label: 'Primary text variant 2',
                                condition: "editor.type=='concept'",
                                multiline: true,
                                placeholder: 'Your message at the top',
                                maxLength: 2200,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'messageOption3',
                                label: 'Primary text variant 3',
                                condition: "editor.type=='concept'",
                                multiline: true,
                                placeholder: 'Your message at the top',
                                maxLength: 2200,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'messageOption4',
                                label: 'Primary text variant 4',
                                multiline: true,
                                condition: "editor.type=='concept'",
                                placeholder: 'Your message at the top',
                                maxLength: 2200,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'messageOption5',
                                label: 'Primary text variant 5',
                                multiline: true,
                                condition: "editor.type=='concept'",
                                placeholder: 'Your message at the top',
                                maxLength: 2200,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'hidden',
                                parseValue: 'true',
                                model: 'message.multilanguage'
                            },
                            {
                                type: 'select',
                                model: 'message.EN.value',
                                label: 'Primary text select',
                                placeholder: 'Your message at the top',
                                options: [
                                    {
                                        key: '{{{[[blockModel]].messageOption1.EN.value}}}',
                                        value: '{{{[[blockModel]].messageOption1.EN.value}}}'
                                    },
                                    {
                                        key: '{{{[[blockModel]].messageOption2.EN.value}}}',
                                        value: '{{{[[blockModel]].messageOption2.EN.value}}}'
                                    },
                                    {
                                        key: '{{{[[blockModel]].messageOption3.EN.value}}}',
                                        value: '{{{[[blockModel]].messageOption3.EN.value}}}'
                                    },
                                    {
                                        key: '{{{[[blockModel]].messageOption4.EN.value}}}',
                                        value: '{{{[[blockModel]].messageOption4.EN.value}}}'
                                    },
                                    {
                                        key: '{{{[[blockModel]].messageOption5.EN.value}}}',
                                        value: '{{{[[blockModel]].messageOption5.EN.value}}}'
                                    }
                                ]
                            },
                            {
                                type: 'divider'
                            },
                            {
                                type: 'select',
                                model: 'cta',
                                condition: "editor.type=='concept'",
                                label: 'Call to action',
                                defaultValue: 'LEARN_MORE',
                                options: {
                                    CALL_NOW: 'Call now',
                                    CONTACT_US: 'Contact us',
                                    LEARN_MORE: 'Learn more',
                                    GET_OFFER: 'Get offer',
                                    SIGN_UP: 'Sign up',
                                    SHOP_NOW: 'Shop now'
                                }
                            }
                        ]
                    },
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Placements',
                        condition: "editor.type=='concept'",
                        items: [
                            {
                                type: 'checkboxList',
                                model: 'publisher_platforms',
                                label: 'Platforms',
                                options: {
                                    facebook: 'Facebook',
                                    instagram: 'Instagram',
                                    messenger: 'Messenger'
                                }
                            },
                            {
                                type: 'checkboxList',
                                model: 'facebook_positions',
                                label: 'Facebook placements',
                                condition: "blockData.publisher_platforms.value.includes('facebook')",
                                options: {
                                    feed: 'Feed',
                                    right_hand_column: 'Right hand column',
                                    marketplace: 'Marketplace',
                                    video_feeds: 'Video feeds',
                                    instant_article: 'Instant article',
                                    search: 'Search',
                                    story: 'Story',
                                    facebook_reels: 'Facebook Reels'
                                }
                            },
                            {
                                type: 'checkboxList',
                                model: 'instagram_positions',
                                label: 'Instagram placements',
                                condition: "blockData.publisher_platforms.value.includes('instagram')",
                                options: {
                                    stream: 'Stream',
                                    story: 'Story',
                                    explore: 'Explore',
                                    explore_home: 'Explore home',
                                    reels: 'Reels',
                                    profile_feed: 'Profile feed'
                                }
                            },
                            {
                                type: 'checkboxList',
                                model: 'messenger_positions',
                                label: 'Messenger placements',
                                condition: "blockData.publisher_platforms.value.includes('messenger')",
                                options: {
                                    messenger_home: 'Messenger home',
                                    story: 'Story'
                                }
                            }
                        ]
                    }
                ]
            },
            {
                path: 'dynamicFrame',
                title: 'Copy',
                items: [
                    {
                        type: 'textMultiLanguage',
                        model: 'headline',
                        readOnly: "{{{editor.type!='concept'}}}",
                        label: 'Headline',
                        validators: [
                            {
                                type: 'length',
                                max: 40,
                                severity: 'warning',
                                message: 'Text over 40 characters is allowed, but may be truncated'
                            }
                        ]
                    },
                    {
                        type: 'textMultiLanguage',
                        model: 'description',
                        readOnly: "{{{editor.type!='concept'}}}",
                        label: 'Description',
                        validators: [
                            {
                                type: 'length',
                                max: 20,
                                severity: 'warning',
                                message: 'Text over 20 characters is allowed, but may be truncated'
                            }
                        ]
                    },
                    {
                        type: 'textMultiLanguage',
                        model: 'link',
                        label: 'Link',
                        helperText: 'Enter the URL for the web page that you want people to visit',
                        tooltip: 'Please make sure to write the full URL, including https://',
                        placeholder: "e.g. 'https://domain.com",
                        validators: [
                            {
                                type: 'url',
                                severity: 'error',
                                message: 'This should be a valid url, including https://'
                            }
                        ]
                    },
                    {
                        type: 'textMultiLanguage',
                        model: 'caption',
                        defaultValue: 'optus.com.au',
                        helperText: 'Display url must have .com.au, e.g. optus.com.au',
                        condition: "editor.type=='concept'",
                        placeholder: 'Show a shortened link',
                        label: 'Display URL (optional)'
                    }
                ]
            }
        ],
        settings: {
            assetSizes: {},
            assetTypes: ['image', 'video', 'dynamicImage', 'dynamicImageDesigned', 'dynamicVideoDesigned', 'dynamicVideo'],
            multiFrame: true,
            dynamicFrames: true
        },
        defaultData: {
            publisher_platforms: {
                value: ['facebook', 'instagram', 'messenger']
            },
            facebook_positions: {
                value: ['feed', 'right_hand_column', 'marketplace', 'video_feeds', 'instant_article', 'search', 'story', 'facebook_reels']
            },
            instagram_positions: {
                value: ['stream', 'story', 'explore', 'explore_home', 'reels', 'profile_feed']
            },
            messenger_positions: {
                value: ['messenger_home', 'story']
            }
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'facebook',
        adType: 'productCarousel',
        identifier: 'meta_product_carousel',
        image: 'https://storage-acceptance.bycape.io/account-12/upload/4367171f-8417-42a8-a7b6-05adf81e118c_6509d2b06e9011ea838ac9db71e62cfbfacebookcarouselad1x.jpg',
        icon: 'https://storage-acceptance.bycape.io/account-12/upload/12115f4a-e992-47ba-9938-c3312db84ff8_554f8740676311eaa0845b851f9a3c4773edddd01d9611eaa088dd3615efadcbFacebook.png',
        title: 'Meta Product Set Carousel',
        description: 'Meta product carousel',

        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'message',
                                label: 'Primary text',
                                placeholder: 'Your message at the top',
                                multiline: true,
                                maxLength: 2200,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 125,
                                        severity: 'warning',
                                        message: 'Text over 125 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'select',
                                model: 'cta',
                                label: 'Call to action',
                                resources: ['facebook_cta']
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'name',
                                label: 'Headline',
                                placeholder: 'Headline of the frame',
                                validators: [
                                    {
                                        type: 'length',
                                        max: 40,
                                        severity: 'warning',
                                        message: 'Text over 40 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'description',
                                label: 'Description',
                                placeholder: 'Description of the frame',
                                validators: [
                                    {
                                        type: 'length',
                                        max: 20,
                                        severity: 'warning',
                                        message: 'Text over 20 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                model: 'link',
                                label: 'Link',
                                helperText: 'Enter the URL for the web page that you want people to visit',
                                tooltip: 'Please make sure to write the full URL, including https://',
                                placeholder: "e.g. 'https://domain.com'",
                                validators: [
                                    {
                                        type: 'url',
                                        severity: 'error',
                                        message: 'This should be a valid url'
                                    }
                                ]
                            },
                            {
                                type: 'text',
                                useValueObject: false,
                                model: 'productSetId',
                                tooltip: 'Insert the product set ID if you are creating a carousel ad.',
                                label: 'Product Set ID'
                            }
                        ]
                    },
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Adset Placements',
                        items: [
                            {
                                type: 'checkboxList',
                                model: 'publisher_platforms',
                                label: 'Platforms',
                                options: {
                                    facebook: 'Facebook',
                                    instagram: 'Instagram',
                                    messenger: 'Messenger'
                                }
                            },
                            {
                                type: 'checkboxList',
                                model: 'facebook_positions',
                                label: 'Facebook placements',
                                condition: "blockData.publisher_platforms.value.includes('facebook')",
                                options: {
                                    feed: 'Feed',
                                    right_hand_column: 'Right hand column',
                                    marketplace: 'Marketplace',
                                    video_feeds: 'Video feeds',
                                    instant_article: 'Instant article',
                                    search: 'Search',
                                    story: 'Story',
                                    facebook_reels: 'Facebook Reels',
                                    profile_feed: 'Profile feed',
                                    instream_video: 'Facebook in-stream videos',
                                    facebook_reels_overlay: 'Ads on Facebook reels'
                                }
                            },
                            {
                                type: 'checkboxList',
                                model: 'instagram_positions',
                                label: 'Instagram placements',
                                condition: "blockData.publisher_platforms.value.includes('instagram')",
                                options: {
                                    stream: 'Stream',
                                    story: 'Story',
                                    explore: 'Explore',
                                    explore_home: 'Explore home',
                                    reels: 'Reels',
                                    profile_feed: 'Profile feed',
                                    ig_search: 'Instagram search results',
                                    profile_reels: 'Instagram profile reels'
                                }
                            },
                            {
                                type: 'checkboxList',
                                model: 'messenger_positions',
                                label: 'Messenger placements',
                                condition: "blockData.publisher_platforms.value.includes('messenger')",
                                options: {
                                    messenger_home: 'Messenger home',
                                    story: 'Story'
                                }
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetTypes: [],
            multiFrame: false,
            dynamicFrames: false,
            noAssets: true
        },
        defaultData: {
            publisher_platforms: {
                value: ['facebook', 'instagram', 'messenger']
            },
            facebook_positions: {
                value: ['feed', 'right_hand_column', 'marketplace', 'video_feeds', 'instant_article', 'search', 'story', 'facebook_reels']
            },
            instagram_positions: {
                value: ['stream', 'story', 'explore', 'explore_home', 'reels', 'profile_feed']
            },
            messenger_positions: {
                value: ['messenger_home', 'story']
            }
        }
    },
    {
        type: 'socialChannelItem',
        channel: 'googleads',
        adType: 'demandGen',
        identifier: 'google_ads_demand_gen',
        image: 'https://storage-acceptance.bycape.io/account-30/upload/e3d0ab6f-8741-460a-b961-7f8bf4cf883a_Screenshot20250121at14.38.22.png',
        icon: 'https://media-storage.lwmicro.com/uploads/campaigndesigner/21f60fe0-679a-11ea-b26a-ebb09930534a_63f864801dc411ea86fb0d12c24c268byoutube.png',
        title: 'Google Ads Demand Gen',
        description: 'Google Ads Demand Gen ad setup',

        interfaceSetup: [
            {
                path: 'general',
                title: 'General',
                items: [
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Copy',
                        items: [
                            {
                                type: 'textMultiLanguage',
                                model: 'headline',
                                label: 'Headline',
                                maxLength: 40,

                                validators: [
                                    {
                                        type: 'length',
                                        max: 40,
                                        severity: 'warning',
                                        message: 'Text over 40 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                condition: 'blockData.headline',
                                model: 'headline2',
                                label: 'Headline 2',
                                maxLength: 40,

                                validators: [
                                    {
                                        type: 'length',
                                        max: 40,
                                        severity: 'warning',
                                        message: 'Text over 40 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                condition: 'blockData.headline2',
                                model: 'headline3',
                                label: 'Headline 3',
                                maxLength: 40,

                                validators: [
                                    {
                                        type: 'length',
                                        max: 40,
                                        severity: 'warning',
                                        message: 'Text over 40 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                condition: 'blockData.headline3',
                                model: 'headline4',
                                label: 'Headline 4',
                                maxLength: 40,

                                validators: [
                                    {
                                        type: 'length',
                                        max: 40,
                                        severity: 'warning',
                                        message: 'Text over 40 characters is allowed, but may be truncated'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                condition: 'blockData.headline4',
                                model: 'headline5',
                                label: 'Headline 5',
                                maxLength: 40,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 40,
                                        severity: 'warning',
                                        message: 'Text over 40 characters is allowed, but may be truncated'
                                    }
                                ]
                            },

                            {
                                type: 'divider'
                            },

                            {
                                type: 'textMultiLanguage',
                                model: 'description',
                                label: 'Description',
                                maxLength: 90,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 90,
                                        severity: 'warning',
                                        message: 'The text should be a maximum of 90 characters.'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                condition: 'blockData.description',
                                model: 'description2',
                                label: 'Description 2',
                                maxLength: 90,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 90,
                                        severity: 'warning',
                                        message: 'The text should be a maximum of 90 characters.'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                condition: 'blockData.description2',
                                model: 'description3',
                                label: 'Description 3',
                                maxLength: 90,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 90,
                                        severity: 'warning',
                                        message: 'The text should be a maximum of 90 characters.'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                condition: 'blockData.description3',
                                model: 'description4',
                                label: 'Description 4',
                                maxLength: 90,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 90,
                                        severity: 'warning',
                                        message: 'The text should be a maximum of 90 characters.'
                                    }
                                ]
                            },
                            {
                                type: 'textMultiLanguage',
                                condition: 'blockData.description4',
                                model: 'description5',
                                label: 'Description 5',
                                maxLength: 90,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 90,
                                        severity: 'warning',
                                        message: 'The text should be a maximum of 90 characters.'
                                    }
                                ]
                            },

                            {
                                type: 'divider'
                            },

                            {
                                type: 'textMultiLanguage',
                                model: 'businessName',
                                label: 'Business Name',
                                maxLength: 25,
                                validators: [
                                    {
                                        type: 'length',
                                        max: 25,
                                        severity: 'warning',
                                        message: 'The text should be a maximum of 90 characters.'
                                    }
                                ]
                            },

                            {
                                type: 'divider'
                            },

                            {
                                type: 'textMultiLanguage',
                                model: 'link',
                                label: 'URL',
                                validators: [
                                    {
                                        type: 'url',
                                        severity: 'error',
                                        message: 'This should be a valid url, including https://'
                                    }
                                ],
                                helperText: 'Enter the URL for the web page that you want people to visit',
                                tooltip: 'Please make sure to write the full URL, including https://',
                                placeholder: "e.g. 'https://domain.com'"
                            }
                        ]
                    },
                    {
                        type: 'subSection',
                        opened: true,
                        title: 'Logo',
                        items: [
                            {
                                type: 'assetGalleryInput',
                                fileType: 'image',
                                label: 'Logo',
                                model: 'logo',
                                imageFormat: 'png',
                                sourceDataModel: 'social.library.images',
                                helperText: 'Image has to be a PNG and the size should be 500x500px',
                                format: 'Logo',
                                outputWidth: 500,
                                outputHeight: 500,
                                useCropper: true
                            }
                        ]
                    }
                ]
            }
        ],
        settings: {
            assetTypes: ['image', 'dynamicImage', 'dynamicImageDesigned'],
            assetSizes: {
                display: {
                    width: 320
                }
            }
        },
        defaultData: {}
    }
];
export { SOCIAL_CHANNEL_ITEMS };
